




import Vue from 'vue';

export default Vue.extend({
  auth: true,
  layout: 'layout-exterior',
  beforeMount() {
    this.$router.replace('/kfmv/il/dashboard/');
  },
});
