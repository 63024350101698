// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import Int64LookupResourceViewModel from '../../../../viewModel/resource/Int64LookupResourceViewModel';
import DebtorBillingPeriodResourceViewModel from '../../../../viewModel/resource/DebtorBillingPeriodResourceViewModel';
import DebtorBillingPeriodResourceDTO from '../../dto/resource/DebtorBillingPeriodResourceDTO';

export default abstract class DebtorBillingPeriodResourceModel extends BaseModel<DebtorBillingPeriodResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }
  /**
  */
  get creatorName() { return this.dto.creatorName; }
  set creatorName(value) { this.dto.creatorName = value; }
  /**
  * @type {date-time}
  */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }
  /**
  * @type {int64}
  */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }
  /**
  */
  get modifierName() { return this.dto.modifierName; }
  set modifierName(value) { this.dto.modifierName = value; }
  /**
  * @type {date-time}
  */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }
  /**
  */
  get caseType() { return this.getObjectModel(() => this.dto.caseType, Int64LookupResourceViewModel)!; }
  set caseType(value) { this.setObjectModel(() => this.dto.caseType, value); }
  /**
  */
  get debtor() { return this.getObjectModel(() => this.dto.debtor, Int64LookupResourceViewModel)!; }
  set debtor(value) { this.setObjectModel(() => this.dto.debtor, value); }
  /**
  * @type {date-time}
  */
  get periodFrom() { return this.dto.periodFrom; }
  set periodFrom(value) { this.dto.periodFrom = value; }
  /**
  * @type {date-time}
  */
  get periodTo() { return this.dto.periodTo; }
  set periodTo(value) { this.dto.periodTo = value; }
  /**
  * @type {int32}
  */
  get casesCount() { return this.dto.casesCount; }
  set casesCount(value) { this.dto.casesCount = value; }
  /**
  * @type {int32}
  */
  get totalMinutes() { return this.dto.totalMinutes; }
  set totalMinutes(value) { this.dto.totalMinutes = value; }
  /**
  * @type {double}
  */
  get totalAmount() { return this.dto.totalAmount; }
  set totalAmount(value) { this.dto.totalAmount = value; }
  /**
  * @type {int32}
  */
  get billedMinutes() { return this.dto.billedMinutes; }
  set billedMinutes(value) { this.dto.billedMinutes = value; }
  /**
  * @type {double}
  */
  get billedAmount() { return this.dto.billedAmount; }
  set billedAmount(value) { this.dto.billedAmount = value; }
  /**
  * @type {int32}
  */
  get exceedingMinutes() { return this.dto.exceedingMinutes; }
  set exceedingMinutes(value) { this.dto.exceedingMinutes = value; }
  /**
  * @type {double}
  */
  get exceedingAmount() { return this.dto.exceedingAmount; }
  set exceedingAmount(value) { this.dto.exceedingAmount = value; }
  /**
  */
  get costWarningLight() { return this.dto.costWarningLight; }
  set costWarningLight(value) { this.dto.costWarningLight = value; }
  /**
  * @type {int64}
  */
  get integrationCallId() { return this.dto.integrationCallId; }
  set integrationCallId(value) { this.dto.integrationCallId = value; }
  /**
  * @type {int32}
  */
  get integrationStatusId() { return this.dto.integrationStatusId; }
  set integrationStatusId(value) { this.dto.integrationStatusId = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.creatorid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.creatorId = value;
    this.dto.creatorName = qs.creatorname;
    this.dto.created = qs.created;
    value = Number.parseFloat(qs.modifierid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modifierId = value;
    this.dto.modifierName = qs.modifiername;
    this.dto.modified = qs.modified;
    this.dto.periodFrom = qs.periodfrom;
    this.dto.periodTo = qs.periodto;
    value = Number.parseFloat(qs.casescount);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.casesCount = value;
    value = Number.parseFloat(qs.totalminutes);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.totalMinutes = value;
    value = Number.parseFloat(qs.totalamount);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.totalAmount = value;
    value = Number.parseFloat(qs.billedminutes);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.billedMinutes = value;
    value = Number.parseFloat(qs.billedamount);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.billedAmount = value;
    value = Number.parseFloat(qs.exceedingminutes);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.exceedingMinutes = value;
    value = Number.parseFloat(qs.exceedingamount);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.exceedingAmount = value;
    this.dto.costWarningLight = qs.costwarninglight;
    value = Number.parseFloat(qs.integrationcallid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.integrationCallId = value;
    value = Number.parseFloat(qs.integrationstatusid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.integrationStatusId = value;
  }
}
