import coreRoutes from '@glittr/frontend-core/src/routes';
import Home from './views/pages/index.vue';

export default {
  mode: 'history',
  base: process.env!.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    ...coreRoutes,
    {
      path: '/:tenant/:workspace',
      name: 'dashboard',
      alias: '/:tenant/:workspace/dashboard',
      component: () => import('./views/pages/app/cases/index.vue'),
    },
    {
      path: '/:tenant/:workspace/cases',
      name: 'cases',
      component: () => import('./views/pages/app/cases/index.vue'),
    },
    {
      path: '/:tenant/:workspace/cases/legalCases',
      name: 'cases-legal',
      component: () => import('./views/pages/app/cases/legalCases/list.vue'),
    },
    {
      path: '/:tenant/:workspace/cases/legalCases/debtors',
      name: 'cases-legal-debtors',
      component: () => import('./views/pages/app/cases/legalCases/debtors/list.vue'),
    },
    {
      path: '/:tenant/:workspace/cases/legalCases/:id',
      name: 'cases-legal-detail',
      component: () => import('./views/pages/app/cases/legalCases/detail.vue'),
    },
    {
      path: '/:tenant/:workspace/cases/wageConsultings',
      name: 'cases-wageConsulting',
      component: () => import('./views/pages/app/cases/wageConsultings/list.vue'),
    },
    {
      path: '/:tenant/:workspace/cases/wageConsultings/debtors',
      name: 'cases-wageConsulting-debtors',
      component: () => import('./views/pages/app/cases/wageConsultings/debtors/list.vue'),
    },
    {
      path: '/:tenant/:workspace/cases/wageConsultings/:id',
      name: 'cases-wageConsulting-detail',
      component: () => import('./views/pages/app/cases/wageConsultings/detail.vue'),
    },
  ],
};
