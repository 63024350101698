import Vue from 'vue';
import core, { CoreOptions } from '@glittr/frontend-core';
import router from './router';
import services from './services/v1';
import ilLanguageSelection from './components/il-language-selection.vue';
import ilDebtorLookup from './components/il-debtor-lookup.vue';

Vue.config.productionTip = false;
// Core entry point
Vue.use(core, {
  // appId: 'custom-mount-id'
  router,
  Vue,
} as CoreOptions);

// APP____________________________________________________________

// STYLES
require('./assets/styles/main.scss');

// PLUGINS
Vue.use(services);

// COMPONENTS
Vue.component('IlLanguageSelection', ilLanguageSelection);
Vue.component('IlDebtorLookup', ilDebtorLookup);

// GUARDS
// Vue.$guards.push(yourGuard);

// LAYOUTS
const reqLayouts = require.context('./views/layouts', true);
reqLayouts.keys().forEach((componentPath) => {
  let componentName = componentPath.substr(componentPath.lastIndexOf('/') + 1, componentPath.length - 1);
  componentName = componentName.replace('.vue', '');
  const component = reqLayouts(componentPath).default;
  Vue.component(componentName, component);
});

// _______________________________________________________________

require('./store');

declare module '@core/plugins/config/config' {
  interface ConfigType {
    'endpoints-get-about'?: string,
    'endpoints-get-app-logs'?: string,
    'endpoints-get-app-systems'?: string,
    'endpoints-get-token'?: string,
    'endpoints-refresh-token'?: string,
    'endpoints-get-contact-id-mappings'?: string,
    'endpoints-get-contact-id-mapping-by-id'?: string,
    'endpoints-get-documents'?: string,
    'endpoints-get-document-by-id'?: string,
    'endpoints-upload-document'?: string,
    'endpoints-download-document'?: string,
    'endpoints-get-integration-call-overview'?: string,
    'endpoints-get-integration-calls'?: string,
    'endpoints-get-integration-call-by-id'?: string,
    'endpoints-get-integration-call-steps-by-call-id'?: string,
    'endpoints-get-integration-tasks'?: string,
    'endpoints-get-locations'?: string,
    'endpoints-lookup-countries'?: string,
    'endpoints-lookup-states'?: string,
    'endpoints-get-me'?: string,
    'endpoints-verify'?: string,
    'endpoints-resend-verification-code'?: string,
    'endpoints-set-password'?: string,
    'endpoints-get-campaign-sync-transform-crm-contact-processes'?: string,
    'endpoints-execute-transform-crm-contact-process'?: string,
    'endpoints-process-pending-integration-calls'?: string,
    'endpoints-get-tenant'?: string,
    'endpoints-register'?: string,
    'endpoints-forgot-password'?: string,
    'endpoints-change-password'?: string,
    'endpoints-get-users'?: string,
    'endpoints-get-user-by-id'?: string,
    'endpoints-update-user'?: string,
    'endpoints-set-user-status'?: string,
    'endpoints-set-user-app-role'?: string,
    'endpoints-get-work-item-custom-fields-by-dossier-type'?: string,
    'endpoints-create-work-item'?: string
  }
}
