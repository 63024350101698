// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import CaseBillingStatusResourceViewModel from '../../../../viewModel/resource/CaseBillingStatusResourceViewModel';
import TimeRecordListResourceViewModel from '../../../../viewModel/resource/TimeRecordListResourceViewModel';
import TimeRecordListResourceDTO from '../../dto/resource/TimeRecordListResourceDTO';

export default abstract class TimeRecordListResourceModel extends BaseModel<TimeRecordListResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }
  /**
  */
  get creatorName() { return this.dto.creatorName; }
  set creatorName(value) { this.dto.creatorName = value; }
  /**
  * @type {date-time}
  */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }
  /**
  * @type {int64}
  */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }
  /**
  */
  get modifierName() { return this.dto.modifierName; }
  set modifierName(value) { this.dto.modifierName = value; }
  /**
  * @type {date-time}
  */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }
  /**
  * @type {int64}
  */
  get caseId() { return this.dto.caseId; }
  set caseId(value) { this.dto.caseId = value; }
  /**
  * @type {date-time}
  */
  get startTime() { return this.dto.startTime; }
  set startTime(value) { this.dto.startTime = value; }
  /**
  * @type {date-time}
  */
  get endTime() { return this.dto.endTime; }
  set endTime(value) { this.dto.endTime = value; }
  /**
  */
  get category() { return this.dto.category; }
  set category(value) { this.dto.category = value; }
  /**
  */
  get caption() { return this.dto.caption; }
  set caption(value) { this.dto.caption = value; }
  /**
  */
  get userName() { return this.dto.userName; }
  set userName(value) { this.dto.userName = value; }
  /**
  * @type {int32}
  */
  get durationMinutes() { return this.dto.durationMinutes; }
  set durationMinutes(value) { this.dto.durationMinutes = value; }
  /**
  * @type {int32}
  */
  get billableMinutes() { return this.dto.billableMinutes; }
  set billableMinutes(value) { this.dto.billableMinutes = value; }
  /**
  * @type {int32}
  */
  get billingStatus() { return this.dto.billingStatus; }
  set billingStatus(value) { this.dto.billingStatus = value; }
  /**
  */
  get isBilled() { return this.dto.isBilled; }
  set isBilled(value) { this.dto.isBilled = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.creatorid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.creatorId = value;
    this.dto.creatorName = qs.creatorname;
    this.dto.created = qs.created;
    value = Number.parseFloat(qs.modifierid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modifierId = value;
    this.dto.modifierName = qs.modifiername;
    this.dto.modified = qs.modified;
    value = Number.parseFloat(qs.caseid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.caseId = value;
    this.dto.startTime = qs.starttime;
    this.dto.endTime = qs.endtime;
    this.dto.category = qs.category;
    this.dto.caption = qs.caption;
    this.dto.userName = qs.username;
    value = Number.parseFloat(qs.durationminutes);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.durationMinutes = value;
    value = Number.parseFloat(qs.billableminutes);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.billableMinutes = value;
    value = Number.parseFloat(qs.billingstatus);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.billingStatus = value;
    value = qs.isBilled === 'true' ? true : qs.isbilled;
    value = qs.isBilled === 'false' ? false : undefined;
    this.dto.isBilled = value;
  }
}
