// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetDebtorPagedListRequestViewModel from '../../viewModel/request/Kfmv/GetDebtorPagedListRequestViewModel';
import DebtorLookupRequestViewModel from '../../viewModel/request/Kfmv/DebtorLookupRequestViewModel';
import GetLegalCasesPagedListRequestViewModel from '../../viewModel/request/Kfmv/GetLegalCasesPagedListRequestViewModel';
import GetTimeRecordsByLegalCasePagedListRequestViewModel from '../../viewModel/request/Kfmv/GetTimeRecordsByLegalCasePagedListRequestViewModel';
import GetTimeRecordsPagedListRequestViewModel from '../../viewModel/request/Kfmv/GetTimeRecordsPagedListRequestViewModel';
import GetWageConsultingsPagedListRequestViewModel from '../../viewModel/request/Kfmv/GetWageConsultingsPagedListRequestViewModel';
import GetTimeRecordsByWageConsultingPagedListRequestViewModel from '../../viewModel/request/Kfmv/GetTimeRecordsByWageConsultingPagedListRequestViewModel';
import DebtorListResourcePagingResultViewModel from '../../viewModel/resource/DebtorListResourcePagingResultViewModel';
import Int64LookupResourcePagingResultViewModel from '../../viewModel/resource/Int64LookupResourcePagingResultViewModel';
import DebtorBillingPeriodResourceViewModel from '../../viewModel/resource/DebtorBillingPeriodResourceViewModel';
import LegalCaseListResourcePagingResultViewModel from '../../viewModel/resource/LegalCaseListResourcePagingResultViewModel';
import LegalCaseResourceViewModel from '../../viewModel/resource/LegalCaseResourceViewModel';
import TimeRecordListResourcePagingResultViewModel from '../../viewModel/resource/TimeRecordListResourcePagingResultViewModel';
import TimeRecordImportRequestViewModel from '../../viewModel/resource/TimeRecordImportRequestViewModel';
import WageConsultingListResourcePagingResultViewModel from '../../viewModel/resource/WageConsultingListResourcePagingResultViewModel';
import WageConsultingResourceViewModel from '../../viewModel/resource/WageConsultingResourceViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getDebtorPagedList(request: GetDebtorPagedListRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-debtor-paged-list'] ?? 'kfmv/Debtors';
    const response = await service.get<any>(endpointPath, {
      query: {
        dossierType: requestDTO.dossierType,
        year: requestDTO.year,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new DebtorListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async debtorLookup(request: DebtorLookupRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-debtor-lookup'] ?? 'kfmv/Debtors/Lookup';
    const response = await service.get<any>(endpointPath, {
      query: {
        dossierType: requestDTO.dossierType,
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new Int64LookupResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getBillingInfo(id: number, dossierType: string, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-billing-info'] ?? 'kfmv/Debtors/{id}/DossierType/{dossierType}/BillingInfo';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    endpointPath = endpointPath.replace(/\{dossierType\}/g, `${dossierType}`);
    const response = await service.post<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new DebtorBillingPeriodResourceViewModel().fromDTO(dto);
  },
  /**  */
  async createDebtorInvoice(id: number, dossierType: string, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-debtor-invoice'] ?? 'kfmv/Debtors/{id}/DossierType/{dossierType}/CreateInvoice';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    endpointPath = endpointPath.replace(/\{dossierType\}/g, `${dossierType}`);
    const response = await service.post<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async getLegalCasesPagedList(request: GetLegalCasesPagedListRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-legal-cases-paged-list'] ?? 'kfmv/LegalCases';
    const response = await service.get<any>(endpointPath, {
      query: {
        debtorId: requestDTO.debtorId,
        billingStatus: requestDTO.billingStatus,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new LegalCaseListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getLegalCaseById(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-legal-case-by-id'] ?? 'kfmv/LegalCases/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new LegalCaseResourceViewModel().fromDTO(dto);
  },
  /**  */
  async getTimeRecordsByLegalCasePagedList(id: number, request: GetTimeRecordsByLegalCasePagedListRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-time-records-by-legal-case-paged-list'] ?? 'kfmv/LegalCases/{id}/TimeRecords';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        caseId: requestDTO.caseId,
        from: requestDTO.from,
        to: requestDTO.to,
        billingStatus: requestDTO.billingStatus,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new TimeRecordListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getTimeRecordsPagedList(request: GetTimeRecordsPagedListRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-time-records-paged-list'] ?? 'kfmv/TimeRecords';
    const response = await service.get<any>(endpointPath, {
      query: {
        caseId: requestDTO.caseId,
        from: requestDTO.from,
        to: requestDTO.to,
        billingStatus: requestDTO.billingStatus,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new TimeRecordListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async importTimeRecords(model: TimeRecordImportRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-import-time-records'] ?? 'kfmv/TimeRecords/Import';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async getWageConsultingsPagedList(request: GetWageConsultingsPagedListRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-wage-consultings-paged-list'] ?? 'kfmv/WageConsultings';
    const response = await service.get<any>(endpointPath, {
      query: {
        debtorId: requestDTO.debtorId,
        billingStatus: requestDTO.billingStatus,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new WageConsultingListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getWageConsultingById(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-wage-consulting-by-id'] ?? 'kfmv/WageConsultings/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new WageConsultingResourceViewModel().fromDTO(dto);
  },
  /**  */
  async getTimeRecordsByWageConsultingPagedList(id: number, request: GetTimeRecordsByWageConsultingPagedListRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-time-records-by-wage-consulting-paged-list'] ?? 'kfmv/WageConsultings/{id}/TimeRecords';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        caseId: requestDTO.caseId,
        from: requestDTO.from,
        to: requestDTO.to,
        billingStatus: requestDTO.billingStatus,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new TimeRecordListResourcePagingResultViewModel().fromDTO(dto);
  },
});
